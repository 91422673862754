<template>
	<div
		class="d-flex align-center justify-center"
		style="min-height: 100vh">
		<div class="bg">
			<v-card
				width="550px"
				class="op ma-12 pa-4"
				dark>
				<v-card-title class="text-h1">Login Error</v-card-title>
				<v-card-text class="text-h5 px-6 pt-6 pb-2">
					<p>
						Login servers unavailable. Check your connection or try again later.
					</p>
					<p>If this problem persists, contact your administrator.</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn
						class="primary"
						to="/login"
						x-large>
						Try again
					</v-btn>
				</v-card-actions>
			</v-card>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style>
.bg {
	background-size: cover;
	background-position: center;
	height: 100%;
}

.op {
	background-color: rgb(0, 0, 0, 0.6) !important;
	border-color: transparent !important;
}
</style>
