var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-center justify-center",
      staticStyle: { "min-height": "100vh" },
    },
    [
      _c(
        "div",
        { staticClass: "bg" },
        [
          _c(
            "v-card",
            {
              staticClass: "op ma-12 pa-4",
              attrs: { width: "550px", dark: "" },
            },
            [
              _c("v-card-title", { staticClass: "text-h1" }, [
                _vm._v("Login Error"),
              ]),
              _c("v-card-text", { staticClass: "text-h5 px-6 pt-6 pb-2" }, [
                _c("p", [
                  _vm._v(
                    " Login servers unavailable. Check your connection or try again later. "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "If this problem persists, contact your administrator."
                  ),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      attrs: { to: "/login", "x-large": "" },
                    },
                    [_vm._v(" Try again ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }